/* eslint-disable */
import instance from "@/services/instance.js";

const GetAllCompletedRFPs = payload => {
  return instance.instanceToken
    .get("rfp/all/completed", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};



export default {
  getAllCompletedRFPs: GetAllCompletedRFPs
};

<template>
  <!-- eslint-disable -->
  <v-container id="rfp-page" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card color="primary" class="px-5 py-3">
          <!-- <template v-slot:heading>
            <div class="display-2 font-weight-light">Open RFPs</div>
            <div class="subtitle-1 font-weight-light">
              A list of RFPs that need to be attended to
            </div>
          </template> -->
          <template v-slot:heading>
            <v-tabs
              v-model="rfpTabs"
              background-color="transparent"
              slider-color="white"
            >
              <span
                class="display-2 font-weight-light mx-3"
                style="align-self: center"
                >RFPs:</span
              >
              <v-tab class="mr-3">
                <font-awesome-icon :icon="['fas', 'file-alt']" class="mr-2" />
                Open RFP
              </v-tab>
              <v-tab class="mr-3">
                <font-awesome-icon :icon="['fas', 'file-alt']" class="mr-2" />
                Completed RFP
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="rfpTabs" class="transparent">
            <!-- Open RFPs -->
            <v-tab-item>
              <v-card-text>
                <v-row
                  class="mx-0"
                  align="center"
                  justify="center"
                  justify-md="start"
                >
                  <v-col cols="12" md="8" lg="5" class="px-0">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      outlined
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-0">
                    <v-data-table
                      :headers="headers"
                      :search="search"
                      :items="items"
                      :loading="isLoading"
                    >
                      <!-- template for hybrid -->
                      <template v-slot:item.isHybridSolutions="{ item }">
                        <v-tooltip bottom v-if="item.isHybridSolutions">
                          <template v-slot:activator="{ on }">
                            <font-awesome-icon
                              v-on="on"
                              class="feature-icon pa-1"
                              :icon="['fas', 'laptop']"
                              color="white"
                              size="xs"
                              :title="`Hybrid Solutions Event - ${item.hybridSolutions}`"
                            />
                          </template>
                          <span>
                            {{
                              `Hybrid Solutions Event ${
                                item.hybridSolutions.length > 0
                                  ? ` - ${item.hybridSolutions[0]}`
                                  : ""
                              }`
                            }}
                          </span>
                        </v-tooltip>
                      </template>
                      <!-- template for trophy -->
                      <template v-slot:item.trophy="{ item }">
                        <font-awesome-icon
                          v-show="item.selected"
                          class="feature-icon pa-1"
                          :icon="['fas', 'trophy']"
                          color="white"
                          size="xs"
                        />
                      </template>
                      <!-- template for duration -->
                      <template v-slot:item.duration="{ item }"
                        >{{
                          item.duration
                            ? item.duration > 1
                              ? `${item.duration} Days`
                              : `${item.duration} Day`
                            : "0 Day"
                        }}
                      </template>
                      <template v-slot:item.status="{ item }">
                        <span class="text-capitalize">{{ item.status }}</span>
                      </template>
                      <!-- template for status (going to event rfp page) -->
                      <template v-slot:item.more="{ item }">
                        <v-btn
                          icon
                          color="grey lighten-1"
                          @click="RouteFn(item._id)"
                        >
                          <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </v-btn>
                      </template>
                      <template v-slot:item.delete="{ item }">
                        <v-dialog
                          v-model="item.isDeleteModal"
                          :key="item._id"
                          persistent
                          max-width="400"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon color="red">
                              <font-awesome-icon :icon="['fas', 'trash-alt']" />
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <h3 class="title text-start">
                                Are you sure you want to delete '{{
                                  item.title
                                }}'?
                              </h3>
                            </v-card-title>
                            <v-card-text>
                              <p class="subtitle-2 text-left">
                                You will be deleting all the data associated
                                with this RFP.
                              </p>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="secondary"
                                text
                                @click="item.isDeleteModal = false"
                                >No, Cancel
                              </v-btn>
                              <v-btn color="error" text @click="deleteFn(item)"
                                >Yes, Delete
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-tab-item>
            <!-- Completed RFPs -->
            <v-tab-item>
              <CompletedRFP />
            </v-tab-item>
          </v-tabs-items>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RFPApicalls from "../../../services/rfp.service";
import CompletedRFP from "./CompletedRFP/CompletedRFP.vue";

export default {
  name: "RFP",
  components: {
    CompletedRFP,
  },
  data() {
    return {
      search: "",
      isLoading: false,
      items: [],
      rfpTabs: 0,
    };
  },
  computed: {
    headers() {
      let adminType = JSON.parse(localStorage.getItem("admin")).admin.adminType;

      if (adminType == "master") {
        return [
          {
            sortable: false,
            text: "",
            value: "trophy",
          },
          {
            sortable: false,
            text: "",
            value: "isHybridSolutions",
          },
          {
            sortable: true,
            text: "Created Date",
            value: "created_Date",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Organisation",
            value: "user.organisation",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Representative",
            value: "user.name",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Event Title",
            value: "title",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Type",
            value: "type1",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Duration",
            value: "duration",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Cities",
            value: "cityCount",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Venues",
            value: "venueCount",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Status",
            value: "status",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Delete",
            value: "delete",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "More",
            value: "more",
            class: "primary--text title",
          },
        ];
      } else {
        return [
          {
            sortable: false,
            text: "",
            value: "trophy",
          },
          {
            sortable: false,
            text: "",
            value: "isHybridSolutions",
          },
          {
            sortable: true,
            text: "Created Date",
            value: "created_Date",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Organisation",
            value: "user.organisation",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Representative",
            value: "user.name",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Event Title",
            value: "title",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Type",
            value: "type1",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Duration",
            value: "duration",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Cities",
            value: "cityCount",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Venues",
            value: "venueCount",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Status",
            value: "status",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "More",
            value: "more",
            class: "primary--text title",
          },
        ];
      }
    },
  },
  mounted() {
    this.isLoading = true;
    RFPApicalls.getAllRFP().then((res) => {
      //console.log(res);
      this.items = res.data.data.map((x) => {
        return {
          ...x,
          created_Date: this.dateformat(x.created_at),
          type1: this.type(x.type, x.rfptype),
          cityCount: x.cityShortList.length,
          venueCount: this.venueCountFn(x.cityShortList),
        };
      });
      this.isLoading = false;
    });
    // .catch(err => {
    //   console.log(err);
    // });
  },
  methods: {
    dateformat(val) {
      var date = new Date(val);
      var dateformatted =
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
      return dateformatted;
    },
    type(val1, val2) {
      var typ = null;
      if (val2 == undefined) {
        typ = val1;
      } else typ = val1 + "-" + val2;

      return typ;
    },
    deleteFn(val) {
      this.isLoading = true;
      let payload = {
        _id: val._id,
      };
      // console.log(payload);
      RFPApicalls.setDelete(payload).then((res) => {
        if (res.data.success) {
          let payload2 = {
            snackBarText: "RFP has been Deleted Successfully",
            snackBarColor: "secondary",
          };
          this.deleteDialog = false;
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
          RFPApicalls.getAllRFP().then((res) => {
            this.items = res.data.data.map((x) => {
              return {
                ...x,
                cityCount: x.cityShortList.length,
                venueCount: this.venueCountFn(x.cityShortList),
              };
            });
          });
        }
        this.isLoading = false;
      });
    },
    RouteFn(id) {
      this.$router.push(`/rfp-view/${id}`);
    },
    venueCountFn(val) {
      // console.log(val);
      let count = 0;
      for (let k = 0; k < val.length; k++) {
        try {
          const element = val[k];
          count = count + element.venue.length;
        } catch (e) {
          console.log("no venues for count");
        }
      }
      return count;
    },
  },
};
</script>
